import * as React from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

import "./style.css"
//import GetJSONfromStorage from "components/HandleLocalStorage"

let vqs_topics = require("assets/vqs_topics.json");
//let vqs_topics = GetJSONfromStorage("vqs_topics")


function MedangleVQSHomepage(props){
    return(
        <div className="columns is-centered" style={{ boxSizing: "border-box" }}>
  <div className="column is-12-mobile is-12-tablet is-12-desktop padding-md">
    <br />
    <p className="is-size-3-desktop is-size-4-tablet is-size-5-mobile global-text has-text-weight-bold">
      Verbal Questions
    </p>
    <p className="global-text">
      Welcome to MedAngle VQs! Go through these to strengthen your concepts and
      get an extra edge on viva day.
    </p>
    <hr />
    <div className="columns is-multiline padding-md">
      <div className="column is-12-mobile is-6-tablet is-3-desktop has-background-grey padding-md">
        <p className="has-text-white is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-weight-bold">
          Endocrinology
        </p>
        <img
          
          src="https://medangle.com/assets/mcq-categories/endocrinology-a670223549937b823cf671ce60e48725ea0288e0e01bd9a605002485ec743c23.svg"
        />
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
        <ul>
          <Link
            data-savepage-href="/vqs/tagged/endocrinology"
           to="/vqs/tagged/endocrinology"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                data-savepage-src="/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                src="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
              />
              <p>
                <span className="gradientText">Endocrinology</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/endocrinology,biochemistry"
           to="/vqs/tagged/endocrinology,biochemistry"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                data-savepage-src="/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                src="https://medangle.com/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
              />
              <p>
                <span className="gradientText">
                  Endocrinology: Biochemistry
                </span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/endocrinology,physiology"
           to="/vqs/tagged/endocrinology,physiology"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                data-savepage-src="/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                src="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
              />
              <p>
                <span className="gradientText">Endocrinology: Physiology</span>
              </p>
            </li>
          </Link>
        </ul>
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop has-background-black padding-md">
        <p className="has-text-white is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-weight-bold">
          Head &amp; Neck
        </p>
        <img
          data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/s3-headandneck-54b01830c026ebd82aa2bb05ecae7516b9713c66dc81c8739059e0649614259f.svg"
          data-savepage-src="/assets/mcq-categories/s3-headandneck-54b01830c026ebd82aa2bb05ecae7516b9713c66dc81c8739059e0649614259f.svg"
          src="https://medangle.com/assets/mcq-categories/s3-headandneck-54b01830c026ebd82aa2bb05ecae7516b9713c66dc81c8739059e0649614259f.svg"
        />
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
        <ul>
          <Link
            data-savepage-href="/vqs/tagged/head-and-neck"
           to="/vqs/tagged/head-and-neck"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                data-savepage-src="/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                src="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
              />
              <p>
                <span className="gradientText">Head &amp; Neck</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/head-and-neck,anatomy"
           to="/vqs/tagged/head-and-neck,anatomy"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                data-savepage-src="/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                src="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
              />
              <p>
                <span className="gradientText">Head &amp; Neck Anatomy</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/head-and-neck,physiology"
           to="/vqs/tagged/head-and-neck,physiology"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                data-savepage-src="/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                src="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
              />
              <p>
                <span className="gradientText">Head &amp; Neck Physiology</span>
              </p>
            </li>
          </Link>
        </ul>
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop color-neuro-bg padding-md">
        <p className="has-text-white is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-weight-bold">
          Neuro
        </p>
        <img
          data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/s3-neuro-861943fbd85416f62644f5e387a1769420d603517b60ab304570e0725db37da3.svg"
          data-savepage-src="/assets/mcq-categories/s3-neuro-861943fbd85416f62644f5e387a1769420d603517b60ab304570e0725db37da3.svg"
          src="https://medangle.com/assets/mcq-categories/s3-neuro-861943fbd85416f62644f5e387a1769420d603517b60ab304570e0725db37da3.svg"
        />
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
        <ul>
          <Link
            data-savepage-href="/vqs/tagged/neuro"
           to="/vqs/tagged/neuro"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                data-savepage-src="/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
                src="https://medangle.com/assets/mcq-categories/medicine-789fcf54f39062627de6a7a27b0cc78e82fdd8be2076f2a78f52689534af0ec9.svg"
              />
              <p>
                <span className="gradientText">Neuro</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/neuro,anatomy"
           to="/vqs/tagged/neuro,anatomy"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                data-savepage-src="/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
            
              />
              <p>
                <span className="gradientText">Neuro: Anatomy</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/neuro,biochemistry"
           to="/vqs/tagged/neuro,biochemistry"
          >
            <li className="gradientBorder">
              <img
              src="https://medangle.com/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                data-savepage-src="/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
               
              />
              <p>
                <span className="gradientText">Neuro: Biochemistry</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/neuro,physiology"
           to="/vqs/tagged/neuro,physiology"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                data-savepage-src="/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
               
              />
              <p>
                <span className="gradientText">Neuro: Physiology</span>
              </p>
            </li>
          </Link>
        </ul>
      </div>
    </div>
    <br />
    <div className="columns is-multiline padding-md">
      <div className="column is-12-mobile is-6-tablet is-3-desktop color-git1-bg padding-md">
        <p className="has-text-white is-size-1-desktop is-size2-tablet is-size-3-mobile has-text-weight-bold">
          GIT
        </p>
        <img
         src="https://medangle.com/assets/mcq-categories/gastrointestinal-0ba31ccbee4e1e9ee4d0e0ec2729c378001f748eefa9d6e3d0d5fcd677376429.svg"
          data-savepage-src="/assets/mcq-categories/gastrointestinal-0ba31ccbee4e1e9ee4d0e0ec2729c378001f748eefa9d6e3d0d5fcd677376429.svg"
        
        />
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
        <ul>
          <Link
            data-savepage-href="/vqs/tagged/gastrointestinal"
           to="/vqs/tagged/gastrointestinal"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/gastrointestinal-0ba31ccbee4e1e9ee4d0e0ec2729c378001f748eefa9d6e3d0d5fcd677376429.svg"
                data-savepage-src="/assets/mcq-categories/gastrointestinal-0ba31ccbee4e1e9ee4d0e0ec2729c378001f748eefa9d6e3d0d5fcd677376429.svg"
               
              />
              <p>
                <span className="gradientText">Gastrointestinal</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/gastrointestinal,anatomy"
           to="/vqs/tagged/gastrointestinal,anatomy"
          >
            <li className="gradientBorder">
              <img
             src="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                data-savepage-src="/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                
              />
              <p>
                <span className="gradientText">Gastrointestinal Anatomy</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/gastrointestinal,biochemistry"
           to="/vqs/tagged/gastrointestinal,biochemistry"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                data-savepage-src="/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
              
              />
              <p>
                <span className="gradientText">
                  Gastrointestinal Biochemistry
                </span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/gastrointestinal,histology"
           to="/vqs/tagged/gastrointestinal,histology"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/histology-4d525acb22f0c31887d67789b3a4f40e8f0fcfac0b39f5443c9b42f1c6c389ee.svg"
                data-savepage-src="/assets/mcq-categories/histology-4d525acb22f0c31887d67789b3a4f40e8f0fcfac0b39f5443c9b42f1c6c389ee.svg"
               
              />
              <p>
                <span className="gradientText">Gastrointestinal Histology</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/gastrointestinal,pathology"
           to="/vqs/tagged/gastrointestinal,pathology"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/pathology-51d8b51d82aeafc76000d9726dbaee3de51dac403bc186cfa11032e9b0fbaf8d.svg"
                data-savepage-src="/assets/mcq-categories/pathology-51d8b51d82aeafc76000d9726dbaee3de51dac403bc186cfa11032e9b0fbaf8d.svg"
              
              />
              <p>
                <span className="gradientText">Gastrointestinal Pathology</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/gastrointestinal,physiology"
           to="/vqs/tagged/gastrointestinal,physiology"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                data-savepage-src="/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
               
              />
              <p>
                <span className="gradientText">
                  Gastrointestinal Physiology
                </span>
              </p>
            </li>
          </Link>
        </ul>
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop color-renal-bg padding-md">
        <p className="has-text-white is-size-1 has-text-weight-bold">Renal</p>
        <img
         src="https://medangle.com/assets/mcq-categories/renal-236b7322ba18f81867797ce9d8b585f37687556895f1b583d825d1c8873198de.svg"
          data-savepage-src="/assets/mcq-categories/renal-236b7322ba18f81867797ce9d8b585f37687556895f1b583d825d1c8873198de.svg"
         
        />
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
        <ul>
          <Link
            data-savepage-href="/vqs/tagged/renal"
           to="/vqs/tagged/renal"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/renal-236b7322ba18f81867797ce9d8b585f37687556895f1b583d825d1c8873198de.svg"
                data-savepage-src="/assets/mcq-categories/renal-236b7322ba18f81867797ce9d8b585f37687556895f1b583d825d1c8873198de.svg"
               
              />
              <p>
                <span className="gradientText">Renal</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/renal,anatomy"
           to="/vqs/tagged/renal,anatomy"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
                data-savepage-src="/assets/mcq-categories/anatomy-b1d54b41d57d495c02c1b6ab16bcf9ee94de171082a0ba4b40d1c7e94c16afe6.svg"
              
              />
              <p>
                <span className="gradientText">Renal Anatomy</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/renal,biochemistry"
           to="/vqs/tagged/renal,biochemistry"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                data-savepage-src="/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
               
              />
              <p>
                <span className="gradientText">Renal Biochemistry</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/renal,physiology"
           to="/vqs/tagged/renal,physiology"
          >
            <li className="gradientBorder">
              <img
                src="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                data-savepage-src="/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
             
              />
              <p>
                <span className="gradientText">Renal Physiology</span>
              </p>
            </li>
          </Link>
        </ul>
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop color-reproductive-bg padding-md">
        <p className="has-text-white is-size-1 has-text-weight-bold">
          Reproductive
        </p>
        <img
          data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/reproduction-d62e66426c646100df8fde8b0a6015be12e34afd8946b4b370d6cf6ce20e657a.svg"
          data-savepage-src="/assets/mcq-categories/reproduction-d62e66426c646100df8fde8b0a6015be12e34afd8946b4b370d6cf6ce20e657a.svg"
         src="https://medangle.com/assets/mcq-categories/reproduction-d62e66426c646100df8fde8b0a6015be12e34afd8946b4b370d6cf6ce20e657a.svg"    />
      </div>
      <div className="column is-12-mobile is-6-tablet is-3-desktop user-mcqs-subject">
        <ul>
          <Link
            data-savepage-href="/vqs/tagged/reproductive"
           to="/vqs/tagged/reproductive"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/reproduction-d62e66426c646100df8fde8b0a6015be12e34afd8946b4b370d6cf6ce20e657a.svg"
                data-savepage-src="/assets/mcq-categories/reproduction-d62e66426c646100df8fde8b0a6015be12e34afd8946b4b370d6cf6ce20e657a.svg"
                
              />
              <p>
                <span className="gradientText">Reproductive</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/reproductive,anatomy"
           to="/vqs/tagged/reproductive,anatomy"
          >
            <li className="gradientBorder">
              <img
               src="https://medangle.com/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                data-savepage-src="/assets/mcq-categories/biochemistry-b636764f7448b35d9e81cdcbed525bb2f8d3ce135f14ab6f5d698bef90672ad0.svg"
                
              />
              <p>
                <span className="gradientText">Reproductive Anatomy</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/reproductive,pathology"
           to="/vqs/tagged/reproductive,pathology"
          >
            <li className="gradientBorder">
              <img
                src="https://medangle.com/assets/mcq-categories/pathology-51d8b51d82aeafc76000d9726dbaee3de51dac403bc186cfa11032e9b0fbaf8d.svg"
                data-savepage-src="/assets/mcq-categories/pathology-51d8b51d82aeafc76000d9726dbaee3de51dac403bc186cfa11032e9b0fbaf8d.svg"
                
              />
              <p>
                <span className="gradientText">Reproductive Pathology</span>
              </p>
            </li>
          </Link>
          <Link
            data-savepage-href="/vqs/tagged/reproductive,physiology"
           to="/vqs/tagged/reproductive,physiology"
          >
            <li className="gradientBorder">
              <img
                data-savepage-currentsrc="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                data-savepage-src="/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
                src="https://medangle.com/assets/mcq-categories/physiology-02e6811ccc9a6aefe45269dc7fc606369f9cd7ddd20d12f0f3843402da13ec35.svg"
              />
              <p>
                <span className="gradientText">Reproductive Physiology</span>
              </p>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  </div>
</div>

    );
}

function VQS_TOPICS_LIST(props){

    return (
        <Layout>
            <Seo title="Verbal Questions" />

            <MedangleVQSHomepage/>
            <div>
                
                <Link to="/vqs/blah/blah">GO TO VQS</Link>
<h1>TOPICS:</h1>
                {vqs_topics.map((topic)=>( <>
                <Link to={"/vqs/tagged/"+topic}>{topic}</Link>
                 <br/>
                </>))}

               
            </div>
        </Layout>
    )
}

export default VQS_TOPICS_LIST
